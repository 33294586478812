<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Virtual Card Transactions</h2>
      </div>
      <div class="col-span-1">
        <div class="relative">
          <search-form
            v-model="query"
            placeholder="Search for user's name, status, etc."
            @submit="(event) => $refs.table.loadAjaxData()"
          />
        </div>
      </div>
    </div>
    <div class="border border-solid border-blue-200">
      <datatable
        :index="true"
        :reverse-index="true"
        :url="`${this.$baseurl}/virtual-card/admin/virtual-cards/transaction`"
        :ajax="true"
        :onClick="goToUser"
        :fillable="true"
        :ajaxPagination="true"
        :data="transactions.data"
        :columns="transactions.columns"
        :query="query"
        ref="table"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      query: '',
      transactions: this.$options.resource([], {
        columns: [
          {
            name: 'user',
            th: 'User ID',
            render: (transaction) => {
              if (!transaction.user) {
                return 'N/A';
              }
              return `${transaction?.user?.id}`;
            },
          },
          {
            name: 'user_name',
            th: 'User Name',
            render: (transaction) => {
              if (!transaction.user) {
                return 'N/A';
              }
              return `${transaction?.user?.name} ${
                transaction?.user?.last_name || ''
              }`;
            },
          },
          {
            name: 'user_phone',
            th: 'User Phone',
            render: (transaction) => {
              if (!transaction.user) {
                return 'N/A';
              }
              return `${transaction?.user?.phone_no}`;
            },
          },

          {
            name: 'amount',
            th: 'Amount',
            render: (transaction) =>
              `$${this.$options.filters.currency(transaction.amount)}`,
          },
          {
            name: 'vc_provider_reference',
            th: 'Reference',
          },
          {
            name: 'narration',
            th: 'Narration',
          },
          {
            name: 'timestamp',
            th: 'Date Created',
            render: (transactions) => {
              return this.$options.filters.dateFormat(
                transactions?.created_at,
                'D, dd M Y'
              );
            },
          },
          {
            name: 'transaction_type',
            th: 'Transaction Type',
          },

          {
            name: 'status',
            th: 'Status',
            render: (transaction, status) => {
              switch (status) {
                case 'success':
                  return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
                                Success
                              </div>`;
                case 'pending':
                  return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                                Pending
                            </div>`;
              }
            },
          },
        ],
      }),
    };
  },
  methods: {
    goToUser({ user_id, user }) {
      return this.$router.push({
        name: 'staff-view',
        params: {
          staffId: user_id?.id || user?.id,
        },
      });
    },
  },
};
</script>
